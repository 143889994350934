var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "solutions-area section-width ptb-100",
      attrs: { id: "terms" }
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "section-title" }, [
          _c("h2", [_vm._v(_vm._s(_vm.$t("navbar.menu_5_1")))])
        ]),
        _c("div", { staticClass: "single-privacy" }, [
          _c("h3", [_vm._v(_vm._s(_vm.$t("section_1.title")))]),
          _c("span", {
            domProps: { innerHTML: _vm._s(this.$t("section_1.content")) }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }