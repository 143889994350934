<template>
    <div id="terms" class="solutions-area section-width ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>{{ $t('navbar.menu_5_1') }}</h2>
            </div>
                <div class="single-privacy">
                    <h3>{{ $t('section_1.title') }}</h3>
                    <span v-html="this.$t('section_1.content')"></span>
                </div>                                        
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PrivacyContent',
        data() {
            return {}
        },
    }
</script>
<style>
 #terms .single-privacy ul li {
        margin-bottom: 0px;
        
 }   
</style>
<style scoped>
    .single-privacy h3
    {
        margin-bottom:15px;
    }
    .single-privacy{
        margin-bottom: 30px;
    }
    .single-privacy ul{
        margin-top: 30px;
    }
 
</style>